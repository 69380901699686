.paginations {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

/* Page link styling */
.pages-itemss-containers {
  background-color: #ce7c7c;
  display: inline-block;
  margin: 0 5px;
  padding: 0px 3px;
  border: 0px solid #ccc;
  border-radius: 5px;
  text-decoration: none;
  color: #ffffff;
}

.pages-links-labelss {
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  margin: 0 0px;
  display: block;
  padding: 5px 15px;
  text-decoration: none;
  border: 0px solid #6a0000;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.pages-itemss-containers:hover {
  background-color: #6a0000;
  color: #000000;
}

.pages-links-labelss:hover {
  color: #ffffff;
}

.activees:hover {
  background-color: #6a0000;
  text-decoration: none;
  color: #ffffff;
}

.aactivess:hover {
  color: rgb(0, 0, 0);
}

.aactivess {
  color: #ffffff;
}

.activees {
  background-color: #6a0000;
  border: 0px solid #ccc;
  border-radius: 5px;
  text-decoration: none;
  color: #ffffff;
}

.activees .pages-links-labels {
  color: #ffffff;
}
